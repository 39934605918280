.pagination {
  display: flex;
  flex-direction: row;
  margin: 10px;
  width: 100%;
}

.pagination li {
  list-style-type: none;
  /* border: 1px solid #222; */
  padding: 8px;
  margin: 0px 5px;
  font-size: 18px;
  cursor: pointer;
}

.pagination button {
  width: 25px;
  height: 25px;
  /* background-color: var(--primary-color); */
  background-color: transparent;
  border: none;
  color: #1d6aba;
  outline: none;
  text-transform: capitalize;
  border: 1px solid #918e8e;
  cursor: pointer;

}

.currentPage {
  background-color: #fff;
  width: 30px;
  height: 25px;
  /* margin-left: 5px;
  margin-right: 5px; */
  border-radius: 0.25rem;
  /* padding: 0.5rem 0.75rem; */
  line-height: 1.25;
  border: 1px solid #dee2e6;
  vertical-align: middle;
  justify-content: center;
}

.currentPage:hover {
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
}
.otherPage {
  width: 30px;
  margin-left: 5px;
  margin-right: 5px;
  /* padding: 0.5rem 0.75rem; */
}
.paginationNumbers {
  display: flex;
  flex-direction: row;
  color: #1d6aba;
}

/* .pageLink:hover {
  color: #1d6aba;
  border-radius: 50%;
} */
