#admin-panel{
    background-color: #e6eff8;
    height: auto;
    padding: 0px 80px;
    min-height: 100vh;


}

@media(max-width:768px){
    #admin-panel{
        background-color: #e6eff8;
        height: auto;
        padding: 0px 0px;
        min-height: 100vh;
    
    
    }
    .dashboard-admin{
        background-color: #FFF;
        padding: 0px 0px;
        margin: 0px 0px;
        border-radius: 8px;
    }
}

.dashboard-admin{
    background-color: #FFF;
    padding: 10px 10px;
    margin: 10px 0px;
    border-radius: 8px;
}

.tab-content{
    /* background-color:#e6eff8 ; */
}
.nav-pills .nav-link{border-radius: 8px;}

.nav-pills .nav-item
{
    padding-right: 8px;
}

.navtop .nav-link{
    border: 1px solid #1d6aba;
}