@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap);
@import url(//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import '~mapbox-gl/dist/mapbox-gl.css';
@import '~leaflet/dist/leaflet.css'; */
* {
  font-family: "QuickSand" !important;
}

body {
  margin: 0;
  padding: 0%;
  font-family: "Quicksand";
}

p {
  color: #616161;
  line-height: 30px;
  font-family: "Quicksand";
}

option {
  font-family: "QuickSand" !important;
}

h1 {
  line-height: 40px;
  font-family: "Quicksand";
  color: #1d6aba;
  font-weight: 700;
  font-size: 34px;
}
h5,
h4,
h3,
h2 {
  color: rgb(0, 0, 0, 0.644);
}

a:hover {
  cursor: pointer;
  text-decoration: none;
}

h2 {
  color: #616161;
  font-weight: lighter;
  line-height: 40px;
}

h4 {
  color: #616161;
  font-weight: lighter;
  line-height: 35px;
}

.w-50 {
  max-width: 50%;
}
.w-49 {
  max-width: 49%;
  width: 49%;
}
.w-48 {
  max-width: 48%;
  width: 48%;
}
.w-47 {
  max-width: 47%;
  width: 47%;
}
.w-46 {
  max-width: 46%;
  width: 46%;
}
.w-45 {
  max-width: 45%;
  width: 45%;
}
.w-44 {
  max-width: 44%;
  width: 44%;
}
.w-43 {
  max-width: 43%;
  width: 43%;
}
.w-42 {
  max-width: 42%;
  width: 42%;
}
.w-41 {
  max-width: 41%;
  width: 41%;
}
.w-40 {
  max-width: 40%;
  width: 40%;
}

.w-93 {
  max-width: 93%;
  width: 93%;
}
.w-95 {
  max-width: 95%;
  width: 95%;
}
.w-100 {
  max-width: 100%;
}
.container {
  margin-top: 0px !important;
}
.login-signup .nav-item a:hover {
  background-color: #1d6aba !important;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
}

.main-nav {
  background-color: #e6eff8;
}
.navbar {
  padding: 0rem;
}

.nav-icons {
  border-radius: 100%;
  padding-top: 5px;
}

.nav-icons:hover {
  background-color: #1d6aba;
  color: #fff;
  transition-timing-function: ease-in-out;
  transition: 1s;
}

.login-signup-after li a:hover {
  color: #1d6aba !important;
  background-color: #fff !important;
}

/* @media (max-width: 500px) {
  
  .login-signup li {
    margin-bottom: 10px;
  }

  .navbar-toggler-icon {
    color: #1d6aba !important;
    height: 40px;
    justify-content: center;
    align-content: center;
  }
} */

td,
th {
  vertical-align: middle !important;
}

td {
  max-width: 170px;
}
@media (max-width: 980px) {
  td {
    min-width: 120px !important;
  }
}

/* Medium devices (tablets, 768px and up) 
The navbar toggle appears at this breakpoint */
@media (max-width: 768px) {
  li .signInbtn {
    font-size: 14px;
    width: 100%;
    max-height: 46px;
    min-height: 46px;
    min-width: 80px;
  }
  li .signInbtnhost {
    font-size: 11px !important;
    width: 100%;
    max-height: 46px;
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
    min-width: 80px;
    justify-content: center;
    align-items: center;
  }
}

.navbar-brand img {
  height: 50px !important;
}

.navbar-brand-after {
  display: none;
}

.login-signup li {
  margin-right: 10px;
}

.login-signup li a:hover {
  color: white;
  background-color: #333456e0;
}

.first-nav {
  /* background-color: rgb(255, 255, 255) ; */
  border-bottom: 1px solid rgba(36, 35, 35, 0.267);
}

.second-nav {
  height: 50px;
  align-items: center !important;
}

.socialIconNav li a {
  margin-right: 20px;
}

.second-nav li {
  margin-right: 25px;
}

.second-nav li a {
  color: rgba(0, 0, 0, 0.836);
  font-size: 14px;
  font-weight: 600 !important;
}

.second-nav li a:hover {
  color: #1d6aba;
  text-decoration: none;
}

.btn {
  background-color: #1d6aba;
  color: white !important;
}

.btn:hover {
  background-color: #1058a5 !important;
}

.navbar-nav li a:hover {
  text-decoration: none !important;
}

li {
  color: black;
}

a {
  color: #5356b8;
}

/* weCanFindYou */
#weCanFindYou {
  background-color: #e6eff8;
  padding: 5% 10% 5%;
}

/* //home page// */
/* @import url("https://fonts.googleapis.com/css2?family=Belleza&display=swap"); */
#home-page0 {
  /* background-color: #e6eff8; */
  padding: 2% 10% 5%;
  background-image: linear-gradient(#e6eff8, #fff);
}

#home-page0 h1 {
  font-family: "Kiwi Maru", serif !important;
  color: rgba(0, 0, 0, 0.631);
  font-weight: 500;
  font-size: 42px;
  line-height: 1.3;
}

#home-page0 h5 a {
  font-family: "Kiwi Maru", serif !important;
  color: rgba(0, 0, 0, 0.487);
}
.newh1 span {
  color: "#1d6aba";
  font-family: "Kiwi Maru", serif !important;
}

#home-page {
  background-color: #e6eff8;
  padding: 5% 10% 5%;
}
.home-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .w-50 {
    max-width: 100%;
    width: 100%;
  }
}

.backbtn:hover {
  transform: scale(110%);
  box-shadow: 9px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}

.sectionNew1 {
  background-color: #fff;
  border-radius: 8px;
  padding: 3% 3%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.section1 {
  align-content: center;
  float: center;
  justify-content: center;
  color: #1d6aba;
}

.section1 p {
  color: black;
}

.section2 {
  margin-top: 10px;
}
.image1 {
  margin-top: 20px;

  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

#home-page1 {
  background-color: #ffffff;
  padding: 5% 10% 5%;
}

@media only screen and (max-width: 400px) {
  #home-page1 {
    background-color: #fff;
    padding: 5% 0% 5%;
  }

  .heading-text {
    font-size: 26px !important;
    font-weight: 600 !important;
  }
  .hsection2 h1 {
    font-size: 26px !important;
    font-weight: 600 !important;
  }
}

@media only screen and (max-width: 400px) {
  h1 {
    font-size: 26px !important;
    font-weight: 800 !important;
    line-height: 30px;
  }
  h2 {
    font-size: 22px !important;
    font-weight: 800 !important;
    line-height: 30px;
  }
  #home-page2 {
    padding: 5% 0% 5% !important;
  }
}
.homepagecard {
  max-height: 410px !important;
  min-height: 410px !important;
}

#home-page1 .main-row {
  width: 80%;
  text-align: center;
  margin: auto;
}

#home-page1 .main-row h1 {
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 39px;
  color: #1d6aba;
}

#login-page .image-container {
  margin-top: 4%;
}

.image2 {
  margin-left: 15%;
  width: 100%;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

.listspace {
  background-color: #1d6aba;
  border: none;
  border-radius: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  color: white;
  font-size: large;
  font-weight: bold;
  padding: 10px 20px;
  float: center;
}

.listspace1 {
  background: transparent;
  border: 2px solid #1d6aba;
  border-radius: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  color: #1d6aba;
  font-size: large;
  font-weight: bold;
  padding: 10px 20px;
  float: center;
}

.hsection2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hsection2 h1 {
  letter-spacing: 2px;
  font-weight: lighter;
  color: #616161;
  line-height: 40px;
  font-size: 35px;
}
.wrapper {
  text-align: center;
  margin-top: 20px;
}

#home-page2 {
  padding: 5% 10% 5%;
}

#home-page2 .main-row {
  width: 80%;
  text-align: center;
  margin: auto;
}
#home-page2 .main-row h1 {
  text-transform: uppercase;
  font-size: 39px;
  color: #1d6aba;
  margin-top: 20px;
}

.hsection3 {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
}

#flippableCard {
  padding: 5% 0% 5%;
  background-color: #e6eff8;
}

/*
flip card
*/
.flippableCard .card {
  border-radius: 0px 0px 0 0;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin: 20px 0px;
}

.flippableCard {
  background-color: #229696;
}
.flippableCard .card,
.card-front,
.card-back {
  border-radius: 40px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin: 20px 0px;
}

.card-flip > div {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 300ms;
  transition-timing-function: linear;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
}

.card-front {
  transform: rotateY(0deg);
}

.card-back {
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
}
.card-back .card-body {
  justify-content: center;
  align-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .card-back .card-body {
    margin-top: 200px;
  }
}
@media only screen and (max-width: 400px) {
  .card-back .card-body {
    margin-top: 80px;
  }
}

.homepageCard {
  max-height: 340px;
}
.homepageCard .card-back .card-body {
  margin-top: 30px;
  max-height: 340px;
}

.card-flip:hover .card-front {
  transform: rotateY(-180deg);
}

.card-flip:hover .card-back {
  transform: rotateY(0deg);
}

#home-page3 {
  padding: 5% 10% 5%;
  background-color: #e6eff8;
}

#home-page3 .main-row {
  width: 80%;
  text-align: center;
  margin: auto;
}
#home-page3 .main-row h1 {
  text-transform: uppercase;
  font-size: 39px;
  color: #1d6aba;
  padding-top: 20px;
  font-weight: bolder;
}

#home-page4 {
  padding: 5% 10% 5%;
  background-color: #fff;
}

#home-page4 .main-row {
  width: 80%;
  text-align: center;
  margin: auto;
  margin-top: 40px;
}
#home-page3 .main-row h1 {
  text-transform: uppercase;
  font-size: 39px;
  color: #1d6aba;
  font-weight: bolder;
}

#home-page5 {
  margin-top: 100px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 0 10%;
  background-color: #f9f5e2;
  margin-bottom: 100px;
}

#different {
  padding: 5% 10% 5%;
  background-color: #fff;
}

@media only screen and (max-width: 400px) {
  #home-page5 {
    padding: 0 !important;
  }

  #home-page5 .main-row {
    width: 100% !important;
    text-align: center;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 105px;
  }
}
#home-page5 .main-row {
  width: 80%;
  text-align: center;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 105px;
}
#home-page5 .main-row h1 {
  text-transform: uppercase;
  font-size: 39px;
  color: #1d6aba;
  padding-top: 50px;
  font-weight: bolder;
}

/* login form */
#login-page {
  background-color: #e6eff8;
  padding: 0% 10% 5%;
}

.login-page {
  background: #fff;
  border-radius: 8px;
  padding: 2% 5%;
  margin-top: 8%;
}

.login-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.signupbtn-google {
  background-color: #ea4335;
  color: #fff;
  padding: 8px;
  font-size: 14px;
  margin-top: 5px;
  width: 100%;
  border-radius: 8px;
  border: none;
  min-width: 230px;
  font-weight: bold;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}

.social-icons-google {
  color: #fff;
  margin-right: 10px;
  size: 20px;
}
.signupbtn-google:hover,
.signupbtn-facebook:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.signupbtn-facebook {
  background-color: #1d6aba;
  color: #fff;
  font-size: 14px;
  padding: 8px;
  margin-top: 5px;
  border-radius: 8px;
  min-width: 230px;
  border: none;
  font-weight: bold;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}
.social-icons-facebook {
  color: #fff;
  margin-right: 10px;
  size: 20px;
}

.signupbtnCreate {
  display: block;
  background-color: transparent;
  color: #616161;
  padding: 8px;
  max-height: 40px;
  min-width: 230px;
  border-radius: 8px;
  border: 1px solid #1d6aba;
  font-weight: bold;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  align-items: center;
}
.signupbtnCreate:hover {
  background-color: #1d6aba;
  color: #fff;
}
.ViewDetails {
  background-color: #1d6aba;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgb(29, 106, 186, 0.5);
  align-items: center;
  text-align: center;
}
.signupbtnLogin {
  background-color: #1d6aba;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  border: none;
  min-width: 230px;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgb(29, 106, 186, 0.5);
  align-items: center;
  text-align: center;
}

.signupbtnLogin:hover {
  color: white;
  text-decoration: none;
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.signupbtnLogin1 {
  display: inline-block;
  background-color: #1d6aba;
  color: #fff;
  font-size: 17px;
  padding: 10px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgb(29, 106, 186, 0.5);
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: all 0.5s;
  margin: 0px;
}

@media (max-width: 450px) {
  .signupbtnLogin1 {
    font-size: 14px;
  }
}
.signupbtnLogin1 span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  color: white;
}
.signupbtnLogin1 span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.signupbtnLogin1:hover span {
  padding-right: 25px;
}

.signupbtnLogin1:hover span:after {
  opacity: 1;
  right: 0;
}
/* .home-container .section1 .signupbtnLogin1:hover{
    text-decoration: none;
    transform: scale(1.1);
    transition: 0.2s;
} */

.signupbtnLoginAlt {
  display: inline-block;
  background: transparent;
  color: #1d6aba;
  font-size: 17px;
  padding: 10px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgb(29, 106, 186, 0.5);
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: all 0.5s;
  margin: 0px;
  border: 1px solid #1d6aba;
}
.signupbtnLoginAlt span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  color: #1d6aba;
}
.signupbtnLoginAlt span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.signupbtnLoginAlt:hover span {
  padding-right: 25px;
}

.signupbtnLoginAlt:hover span:after {
  opacity: 1;
  right: 0;
}

.form-control {
  border-radius: 8px;
  box-shadow: 4px 4px 12px -9px rgba(0, 0, 0, 0.67);
  -webkit-box-shadow: 4px 4px 12px -9px rgba(0, 0, 0, 0.67);
  -moz-box-shadow: 4px 4px 12px -9px rgba(0, 0, 0, 0.67);
}

.form-check {
  margin-left: 10px;
}

.form-group h5 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgb(167, 162, 162);
  line-height: 0.1em;
  margin: 30px 0px 20px 0px;
}

.form-group span {
  background: #fff;
  padding: 0 10px;
  color: #bdb4b4;
}

.dropdown-menu {
  width: 220px;
}

.dropdown-item:hover {
  background-color: #1d6aba;
  color: white;
}

/* about page */

#about-page {
  background-color: #e6eff8;
  padding: 0 10% 10% 7%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

#about-page p {
  color: rgba(0, 0, 0, 0.85);
}
.about-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 0 0 0;
}

#about2 {
  margin-top: 80px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background-color: #f9f5e2;
  padding: 8% 10%;
}
.about2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.coln {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

/* contact page */
#contact-page {
  padding: 0 10% 5%;
  background-color: #e6eff8;
}
.contact-page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
#contact-page1 {
  padding: 0 10% 5%;
}
.contact-page1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.contact-form {
  background-color: #fff;
  padding: 2% 5%;
  border-radius: 8px;
}

.touch {
  padding-top: 20px;
}
.contact-page1 .touch li {
  list-style: none;
  padding-top: 20px;
  padding: 10px;
  font-size: 20px;
}

.map-section {
  padding-top: 30px;
}

/* add listing page */
#add-listing {
  padding: 0% 10% 5%;
  background-color: #e6eff8;
}

select {
  font-family: "Quicksand" !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
}

/* listing grid page */
#listing-grid {
  padding: 2% 2% 5%;
  background-color: #e6eff8;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.listing-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #e6eff8;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  min-height: 100vh;
}
.half-map {
  height: 100vh;
  position: fixed;
  z-index: 1;
  width: 100%;
  /* border: 2px solid #fff; */
}

.half-grid {
  margin-left: 55%;
}

@media (max-width: 768px) {
  .half-map {
    position: absolute;
    height: 300px;
    width: 100%;
    padding-right: 5%;
  }
  .half-grid {
    margin-top: 310px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .half-map {
    position: absolute;
    height: 400px;
    width: 100%;
    padding-right: 5%;
  }
  .half-grid {
    margin-top: 410px;
  }
}

@media (max-width: 992px) {
  .half-grid {
    margin-left: 0;
  }
}

.search-section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.image-gallery-image {
  height: 400px !important;
}

.search-filter {
  background-color: #fff;
  border: 1px solid white !important;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(242, 230, 230, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.listing-grid .card a {
  color: #1d6aba;
}
.listing-grid .card a:hover {
  color: #0d4683;
}

.card {
  border-radius: 20px 20px 8px 8px;
  box-shadow: 0 4px 8px 0 rgba(242, 230, 230, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.card-img-top {
  border-radius: 20px 20px 0 0;
  height: 150px;
}

.listing-grid .card {
  border-radius: 20px 0 !important ;
  box-shadow: 0 4px 8px 0 rgba(242, 230, 230, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.listing-grid .card-img-top {
  border-radius: 20px 0 !important ;
  height: 150px;
}
/* Footer */
footer {
  color: white;
}
footer p {
  color: white;
}
footer a {
  color: white;
}
.footer-list li {
  margin-bottom: 10px;
}

/* listing-details page? */
#listing-details {
  background-color: #e6eff8;
  padding: 2% 5% 5%;
}

.listing-details {
  /* height:500px; */
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* .top-img {
  width: 60%;
  padding: 10px;
} */
.top-img img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.image-grid {
  width: 35%;
  display: grid;
  grid-template-areas:
    "image1 image2"
    "image3 image4";
  grid-gap: 10px;
  padding: 10px;
}
.image-grid img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.grid-item1 {
  grid-area: image1;
}
.grid-item2 {
  grid-area: image2;
}
.grid-item3 {
  grid-area: image3;
}
.grid-item4 {
  grid-area: image4;
}

.main-details {
  padding: 2% 5% 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* .details {
  width: 60%;
  padding: 10px;
} */
.modal-backdrop {
  z-index: -3;
}

.card-header {
  background-color: #1d6aba;
  color: white;
}

.booking-card .card {
  border-radius: 8px;
  background-color: #f9f5e2;
}

.details li {
  margin-bottom: 10px;
}

.facilities li {
  display: inline;

  /* margin-right: 70px; */
}

#host-details {
  background-color: white;
  padding: 5% 8% 5%;
}

#reviews {
  background-color: #f9f5e2;
  padding: 5% 10% 5%;
}

.sidebar {
  position: fixed;
  z-index: 10;
  left: 0;
  width: 15%;
  background: #e6eff8;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1d6aba;
}

.nav-pills .nav-link {
  border-radius: 0px;
}

.nav-link {
  color: black;
}
.sidebar-content {
  margin-left: 15%;
  background-color: #fff;
  padding: 20px;
  overflow: auto;
}

/* sidebar */

#main-dashboard {
  background-color: #e6eff8;
  height: auto;
  min-height: 100vh;
}

.sidebar-row {
  width: 100%;
  display: flex;
  background-color: #e6eff8;
}
.sidebar-container {
  height: 100vh;
  width: 13%;
  background-color: #fff;
  border-right: 1px solid rgb(190, 183, 183);
  border-top: 1px solid rgb(190, 183, 183);
  position: fixed;
}
.sidebar-container li {
  margin-bottom: 10px;
  padding: 10px 10px 10px 10px;
  font-weight: 600;
  align-items: center;
  cursor: pointer;
  color: rgba(5, 5, 5, 0.644);
}

.sidebar-container a {
  left: 0;
  height: 30px;
  display: block;
  color: rgba(5, 5, 5, 0.644);
}

.sidebar-container li:hover {
  color: #fff;
  background-color: #1d6aba;
}
.sidebar-container li:hover a {
  color: #fff;
  background-color: #1d6aba;
  text-decoration: none;
}

.dashboard-content {
  width: 87%;
  background-color: #e6eff8;
  margin-left: 13%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}
.dashboard-content-home {
  width: 100%;
  background-color: #fff;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dashboard-title {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border-top: 1px solid rgb(190, 183, 183);
  padding-left: 20px;
  border-bottom: 1px solid rgb(190, 183, 183);
  position: fixed;
  z-index: 200;
  margin-bottom: 5% !important;
}

@media (max-width: 768px) {
  .dashboard-title {
    text-align: center;
  }
}

.dashboard-top {
  /* background-color: white; */
  padding: 10px;
  margin: 4%;
  margin-top: 100px;
  border-radius: 4px;
  z-index: -1;
}
.dashboard-top .tiles {
  /* margin-right: 0; */
  /* max-width: 350px; */
  background-color: #fff;
  border-right: 1px solid rgb(190, 183, 183);
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}

@media (max-width: 768px) {
  .dashboard-top .tiles {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    max-width: 500px;
    background-color: #fff;
    border-right: 1px solid rgb(190, 183, 183);
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 10px 20px;
    box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  }
}

.title {
  font-weight: 600;
}
.count {
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: 45px;
  font-weight: 100;
  color: #1d6aba;
}
.manage-link {
  float: right;
  border: none;
  background: transparent;
  border: 1px solid #1d6aba;
  border-radius: 4px;
  padding: 5px 10px;
}

.manage-link:hover {
  background: #1d6aba;
  color: #fff;
  border: 1px solid #1d6aba;
}

.dashboard-second1 {
  background-color: white;
  padding: 10px;
  margin: 4%;
  border-radius: 4px;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}
.renter-dashboard-second {
  background-color: white;
  padding: 10px;
  margin: 4%;
  margin-top: 10px;
  border-radius: 4px;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}
.dashboard-second {
  background-color: white;
  padding: 10px;
  margin: 4%;
  margin-top: 100px;
  border-radius: 4px;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}

.dashboard-renter-second {
  background-color: white;
  padding: 10px;
  margin: 4%;
  border-radius: 4px;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}
.dashboard-second-title {
  padding: 10px;
  padding-bottom: 20px;
}
.dashboard-second a {
  align-content: center;
  align-items: center;
}

/* My lisitngs */
.ant-input-search-button {
  background-color: #1d6aba;
  border-radius: 8px;
}

.manage-button {
  /* float: right; */
  border: none;
  background: transparent;
  border: 1px solid #1d6aba;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: bold;
}
.manage-button:hover {
  background: #1d6aba;
  color: #fff;
  border: 1px solid #1d6aba;
}
.manage-buttonAlt {
  /* float: right; */
  border: none;
  background: rgb(233, 14, 14);
  border-radius: 4px;
  padding: 5px 10px;
  color: white;
  font-size: 13px;
  font-weight: bold;
}

.manage-buttonAlt:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.more-buttons .manage-button,
.manage-buttonAlt {
  margin-right: 8px;
}

.mylistings tbody td .manage-button,
.manage-button1 {
  margin-right: 4px;
}

.mylistings thead th {
  font-size: 15px;
  padding: 30px 0;
}

.mylistings tbody td {
  padding: 15px 0;
}

/* add listing page dashboard */

#add-listing-here {
  background-color: white;
  padding: 10px 30px;
  margin: 4%;
  margin-top: 100px;
  border-radius: 4px;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}

#add-listing-here {
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}

.mylistings td h5 {
  margin-left: auto;
  margin-right: auto;
}

.accepted {
  border: none;
  color: white;
  background-color: green;
  border-radius: 4px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding: 2px 3px;
  font-size: 13px;
}
.cancel {
  border: none;
  color: white;
  background-color: rgb(82, 4, 4);
  border-radius: 4px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding: 2px 3px;
  font-size: 13px;
}

.rejected {
  color: white;
  background-color: red;
  border-radius: 4px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding: 2px 3px;
  font-size: 13px;
}
.pending {
  color: white;
  background-color: #ee00ff;
  border-radius: 4px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding: 2px 3px;
  font-size: 13px;
}

.requested {
  color: white;
  background-color: #2084f0;
  border-radius: 4px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding: 2px 3px;
  font-size: 13px;
}
.new {
  color: white;
  background-color: #1d6aba;
  border-radius: 4px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding: 2px 3px;
  font-size: 13px;
}

.manage-button1 {
  border: none;
  background: transparent;
  border: 1px solid #1d6aba;
  border-radius: 4px;
  padding: 5px 10px;
  color: #1d6aba;
}
.manage-button1:hover {
  background: #1d6aba;
  color: #fff;
  border: 1px solid #1d6aba;
  text-decoration: none;
}

/* Profile page */

.profilePage {
  background-color: #e6eff8;
  margin: 4%;
  border-radius: 4px;
  margin-top: 100px;
}

.profileDetails {
  background-color: #fff;
  border-radius: 8px;
  padding: 3% 3%;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}

/* Host page */

#host-page {
  background-color: #e6eff8;
  padding: 5% 10% 5%;
}
.host-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.imageHost {
  /* margin-left: 15%;
  margin-top: 20px; */
  width: 100%;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
.host-listing-section {
  margin-top: 20px;
  padding: 5%;
  background-color: #f9f5e2;
  align-items: center;
  border-radius: 8px;
}
.host-container .section2 {
  margin-top: 0px;
}

.host-container ul li {
  margin-bottom: 9px;
}
#host-page3 {
  padding: 5% 10% 5%;
  background-color: #f9f5e2;
  margin: 100px 0;
}

#host-page3 .main-row {
  width: 80%;
  text-align: center;
  margin: auto;
}
#host-page3 .main-row h1 {
  text-transform: uppercase;
  font-size: 39px;
  color: #1d6aba;
  padding-top: 20px;
  font-weight: bolder;
}

#host-page3 .hsection3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#login-page image-container {
  text-align: center;
}

.leaflet-container {
  width: 300px;
  height: 100px;
}

.custom-file-input {
  border-radius: 15px;
  background-color: #1d6aba;
}

.custom-file0 {
  border-radius: 15%;
}

.hostReservationDashboard {
  background-color: white;
  margin: 4% 1% 4% 4%;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}

.hostReservationDashboard li {
  margin-bottom: 14px;
}

.hostConfirmation {
  background-color: white;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 10px 20px;
  margin: 4% 4% 4% 1%;
  border-radius: 4px;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}

/* forgot password page */

#reset {
  background-color: #e6eff8;
  height: 100vh;
  padding: 3% 10% 5%;
}

.reset {
  align-content: center;
  background-color: #fff;
  /* width: 60%; */
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 3%;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 4px 6px 29px -10px rgba(0, 0, 0, 0.06);
}

/* page not found */
.error-page {
  height: 100vh;
  padding: 5%;
}

.error-page1 {
  height: 100vh;
  padding: 1% 5%;
}

.txt1 {
  color: #0070bb;
  font-size: 40px;
  font-weight: 800;
  text-align: center;
  margin-top: 20px;
}

.txt2 {
  color: #c8c6ce;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}

.txt3 {
  color: #c8c6ce;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}

a .button {
  color: ffffff;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

#orbit-system {
  position: relative;
  width: 18em;
  height: 18em;
  margin: 0px auto;
}

.system {
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotateX(75deg) rotateY(-30deg);
  transform-style: preserve-3d;
}

.planet,
.satellite-orbit,
.satellite {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-style: preserve-3d;
}

.planet {
  width: 9em;
  height: 9em;
  margin-top: -20em;
  margin-left: -4.5em;

  border-radius: 50%;
  background-color: none;
  color: white;

  text-align: center;
  line-height: 9em;
  transform: rotateY(30deg) rotateX(-75deg);
}

.satellite-orbit {
  width: 16em;
  height: 16em;
  margin-top: -8em;
  margin-left: -8em;

  /*border: 1px solid black;*/
  border-radius: 50%;

  -webkit-animation-name: orbit;
  animation-name: orbit;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.satellite {
  top: 100%;
  width: 7em;
  height: 7em;
  margin-top: -1.5em;
  margin-left: -1.5em;
  color: #fefefe;
  background-color: #0070bb;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
  line-height: 7em;
  -webkit-animation-name: invert-orbit;
  animation-name: invert-orbit;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes orbit {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes orbit {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@-webkit-keyframes invert-orbit {
  0% {
    transform: rotateZ(360deg) rotateY(30deg) rotateX(-75deg);
  }

  100% {
    transform: rotateZ(0deg) rotateY(30deg) rotateX(-75deg);
  }
}
@keyframes invert-orbit {
  0% {
    transform: rotateZ(360deg) rotateY(30deg) rotateX(-75deg);
  }

  100% {
    transform: rotateZ(0deg) rotateY(30deg) rotateX(-75deg);
  }
}

/* // dropdown */

.dropdown .input {
  border-radius: 6px;
  height: 45px;
}

.spbRequest {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background-color: #e6eff8;
}

.modal {
  background: rgba(0, 0, 0, 0.398);
}

/* // responsive */

@media (max-width: 768px) {
  .hostsidebar {
    display: none !important;
  }
  .sidebar-container {
    width: 0% !important;
    display: none !important;
  }
  .dashboard-content {
    width: 100%;
    margin-left: 0% !important;
  }
}
@media (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
    border-radius: 0;
  }
}

@media (max-width: 992px) {
  .navbar {
    padding: 1rem 0;
  }

  .login-signup li {
    margin-bottom: 10px;
  }

  .navbar-toggler-icon {
    color: #1d6aba !important;
    height: 40px;
    justify-content: flex-end;
    align-content: center;
  }
}
@media (min-width: 992px) {
  #dropper1 {
    display: none !important;
  }
  #dashboard {
    display: none !important;
  }
  #navbarSupportedContent {
    display: none !important;
  }
}

@media (min-width: 768px) {
  #navbarSupportedContent {
    display: none !important;
  }
  #dropper {
    display: none !important;
  }
}
.main-nav {
  box-shadow: 0 4px 2px -2px rgba(71, 63, 63, 0.2) !important;
}

@media (max-width: 768px) {
  #flippableCard {
    display: none;
  }
}

/* //input number css */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 769px) {
  #home-page0 {
    /* background-color: #e6eff8; */
    text-align: center;
    padding: 2% 5%;
  }
  .logo-nav {
    width: 50% !important;
  }
  .small-social-icons a:hover {
    color: #1d6aba !important;
  }
}




.register{
    margin-top: 3%;
    padding: 3%;
    border-radius: 8px;
}
.register-left{
    text-align: center;
    color: #fff;
    margin-top: 4%;
    
}
/* .register-left input{
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    width: 60%;
    background: #f8f9fa;
    font-weight: bold;
    color: #383d41;
    margin-top: 30%;
    margin-bottom: 3%;
    cursor: pointer;
} */
.register-right{
    background: #fff;
    border-radius: 8px;
    box-shadow: 9px 13px 13px -7px rgba(0,0,0,0.79);
-webkit-box-shadow: 9px 13px 13px -7px rgba(0,0,0,0.79);
-moz-box-shadow: 9px 13px 13px -7px rgba(0,0,0,0.79);

}
.register-left img{
    margin-left: 15%;
    width: 100%;
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
.register-left p{
    font-weight: lighter;
    padding: 12%;
    margin-top: -9%;
}
.register .register-form{
    padding: 10%;
    margin-top: 10%;
}
.btnRegister{
    float: right;
    margin-top: 10%;
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    background: #1d6aba;
    color: #fff;
    font-weight: 600;
    width: 50%;
    cursor: pointer;
}
.register .nav-tabs{
    margin-top: 3%;
    border: none;
    background: #1d6aba;
    border-radius: 1.5rem;
    width: 28%;
    float: right;
}
.register .nav-tabs .nav-link{
    padding: 2%;
    height: 34px;
    font-weight: 600;
    color: #fff;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover{
    border: none;
}
.register .nav-tabs .nav-link.active{
    width: 100px;
    color: #1d6aba;
    border: 2px solid #1d6aba;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}
.register-heading{
    text-align: center;
    margin-top: 8%;
    margin-bottom: -15%;
    color: #495057;
}
.leaflet-container {
    width: 100%;
    height: 100%;
  }
.pagination {
  display: flex;
  flex-direction: row;
  margin: 10px;
  width: 100%;
}

.pagination li {
  list-style-type: none;
  /* border: 1px solid #222; */
  padding: 8px;
  margin: 0px 5px;
  font-size: 18px;
  cursor: pointer;
}

.pagination button {
  width: 25px;
  height: 25px;
  /* background-color: var(--primary-color); */
  background-color: transparent;
  border: none;
  color: #1d6aba;
  outline: none;
  text-transform: capitalize;
  border: 1px solid #918e8e;
  cursor: pointer;

}

.currentPage {
  background-color: #fff;
  width: 30px;
  height: 25px;
  /* margin-left: 5px;
  margin-right: 5px; */
  border-radius: 0.25rem;
  /* padding: 0.5rem 0.75rem; */
  line-height: 1.25;
  border: 1px solid #dee2e6;
  vertical-align: middle;
  justify-content: center;
}

.currentPage:hover {
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
}
.otherPage {
  width: 30px;
  margin-left: 5px;
  margin-right: 5px;
  /* padding: 0.5rem 0.75rem; */
}
.paginationNumbers {
  display: flex;
  flex-direction: row;
  color: #1d6aba;
}

/* .pageLink:hover {
  color: #1d6aba;
  border-radius: 50%;
} */

#admin-panel{
    background-color: #e6eff8;
    height: auto;
    padding: 0px 80px;
    min-height: 100vh;


}

@media(max-width:768px){
    #admin-panel{
        background-color: #e6eff8;
        height: auto;
        padding: 0px 0px;
        min-height: 100vh;
    
    
    }
    .dashboard-admin{
        background-color: #FFF;
        padding: 0px 0px;
        margin: 0px 0px;
        border-radius: 8px;
    }
}

.dashboard-admin{
    background-color: #FFF;
    padding: 10px 10px;
    margin: 10px 0px;
    border-radius: 8px;
}

.tab-content{
    /* background-color:#e6eff8 ; */
}
.nav-pills .nav-link{border-radius: 8px;}

.nav-pills .nav-item
{
    padding-right: 8px;
}

.navtop .nav-link{
    border: 1px solid #1d6aba;
}
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}
.dropdown input{
  width: 100%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100%;
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
